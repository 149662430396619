<template>
  <HeaderBlock />
  <div class="box">
    <AsideBlock />
    <main class="main">
      <div class="py-4 text-center">
        <h2 class="text-white px-12 uppercase mb-2 text-3xl">{{ $t('verification.title') }}</h2>
      </div>
      <div class="py-3 grid grid-cols-1 md:grid-cols-3 gap-6">
        <article class="rounded-xl border-dashed border-4 p-3 text-center">
          <div
            class="flex flex-col items-center justify-center my-3 relative border rounded-full border-white w-28 aspect-square mx-auto"
          >
            <div
              class="text-4xl aspect-square absolute z-10 text-white top-0 left-0 w-full h-full flex items-center justify-center rounded-full bg-neutral-500/40"
            >
              <span> &#x2B;</span>
            </div>
            <div class="rounded-full relative">
              <img src="@/assets/new-images/address-book-solid.svg" class="w-12" />
            </div>
          </div>
          <div class="text-neutral-400 uppercase text-xl">{{ $t('verification.upload') }}</div>
          <p class="text-neutral-300/60">{{ $t('verification.drop') }}</p>
        </article>
        <article class="rounded-xl border-dashed border-4 p-3 text-center">
          <div
            class="flex flex-col items-center justify-center my-3 relative border rounded-full border-white w-28 aspect-square mx-auto"
          >
            <div
              class="text-4xl aspect-square absolute z-10 text-white top-0 left-0 w-full h-full flex items-center justify-center rounded-full bg-neutral-500/40"
            >
              <span> &#x2B;</span>
            </div>
            <div class="rounded-full relative">
              <img src="@/assets/new-images/house-solid.svg" class="w-12" />
            </div>
          </div>
          <div class="text-neutral-400 uppercase text-xl">{{ $t('verification.upload2') }}</div>
          <p class="text-neutral-300/60">{{ $t('verification.drop') }}</p>
        </article>
        <article class="rounded-xl border-dashed border-4 p-3 text-center">
          <div
            class="flex flex-col items-center justify-center my-3 relative border rounded-full border-white w-28 aspect-square mx-auto"
          >
            <div
              class="text-4xl aspect-square absolute z-10 text-white top-0 left-0 w-full h-full flex items-center justify-center rounded-full bg-neutral-500/40"
            >
              <span> &#x2B;</span>
            </div>
            <div class="rounded-full relative">
              <img src="@/assets/new-images/credit-card-regular.svg" class="w-12" />
            </div>
          </div>
          <div class="text-neutral-400 uppercase text-xl">{{ $t('verification.upload3') }}</div>
          <p class="text-neutral-300/60">{{ $t('verification.drop') }}</p>
        </article>
        <article class="rounded-xl border-dashed border-4 p-3 text-center">
          <div
            class="flex flex-col items-center justify-center my-3 relative border rounded-full border-white w-28 aspect-square mx-auto"
          >
            <div
              class="text-4xl aspect-square absolute z-10 text-white top-0 left-0 w-full h-full flex items-center justify-center rounded-full bg-neutral-500/40"
            >
              <span> &#x2B;</span>
            </div>
            <div class="rounded-full relative">
              <img src="@/assets/new-images/credit-card-regular.svg" class="w-12" />
            </div>
          </div>
          <div class="text-neutral-400 uppercase text-xl">{{ $t('verification.upload4') }}</div>
          <p class="text-neutral-300/60">{{ $t('verification.drop') }}</p>
        </article>
        <article class="rounded-xl border-dashed border-4 p-3 text-center">
          <div
            class="flex flex-col items-center justify-center my-3 relative border rounded-full border-white w-28 aspect-square mx-auto"
          >
            <div
              class="text-4xl aspect-square absolute z-10 text-white top-0 left-0 w-full h-full flex items-center justify-center rounded-full bg-neutral-500/40"
            >
              <span> &#x2B;</span>
            </div>
            <div class="rounded-full relative">
              <img src="@/assets/new-images/id-card-regular.svg" class="w-12" />
            </div>
          </div>
          <div class="text-neutral-400 uppercase text-xl">{{ $t('verification.upload5') }}</div>
          <p class="text-neutral-300/60">{{ $t('verification.drop') }}</p>
        </article>
        <article class="rounded-xl border-dashed border-4 p-3 text-center">
          <div
            class="flex flex-col items-center justify-center my-3 relative border rounded-full border-white w-28 aspect-square mx-auto"
          >
            <div
              class="text-4xl aspect-square absolute z-10 text-white top-0 left-0 w-full h-full flex items-center justify-center rounded-full bg-neutral-500/40"
            >
              <span> &#x2B;</span>
            </div>
            <div class="rounded-full relative">
              <img src="@/assets/new-images/user-solid.svg" class="w-12" />
            </div>
          </div>
          <div class="text-neutral-400 uppercase text-xl">{{ $t('verification.upload6') }}</div>
          <p class="text-neutral-300/60">{{ $t('verification.drop') }}</p>
        </article>
      </div>
      <div class="my-3">
        <div class="w-full bg-slate-600 h-14 rounded-full"></div>
        <div class="text-slate-500 text-lg py-2">{{ $t('verification.doc') }}</div>
      </div>
      <div class="main-deposits">
        <div class="main-deposits-title">{{ $t('verification.list') }}</div>
        <div class="table-responsive">
          <table class="main-cabinet">
            <thead>
              <tr class="fs">
                <th class="nomobile fs">{{ t("home.table1") }}</th>
                <th class="fs">{{ t("home.table2") }}</th>
                <th class="fs">{{ t("home.table3") }}</th>
                <th class="fs">{{ t("home.table4") }}</th>
                <th class="fs">{{ t("home.table5") }}</th>
              </tr>
            </thead>
            <tbody class="bg-slate-900"></tbody>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import HeaderBlock from "@/components/HeaderBlock.vue";
import AsideBlock from "@/components/AsideBlock.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>
