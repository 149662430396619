<template>
    <HeaderBlock />
    <div class="box">
        <AsideBlock />
        <main class="main">
            <div class="trade">
                <div class="trade-title">{{ $t('trade.title') }}</div>
                <!-- TradingView Widget BEGIN -->
                <div class="tradingview-widget-container" style="height:100%;width:100%">
                    <div class="tradingview-widget-container__widget" style="height:calc(100% - 32px);width:100%"></div>
                    <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span class="blue-text">Track all markets on TradingView</span></a></div>
                </div>
                <!-- TradingView Widget END -->
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBlock from '@/components/HeaderBlock.vue'
import AsideBlock from '@/components/AsideBlock.vue'

export default{
    name: 'TradeView',
    components: {
        HeaderBlock,
        AsideBlock
    },
    mounted() {
        const widgetPlaceholder = document.getElementsByClassName('tradingview-widget-container')[0];
        widgetPlaceholder.replaceChildren(); // empty placeholder
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js'
        script.async = true;
        script.innerHTML = JSON.stringify({
        "autosize": true,
        "symbol": "NASDAQ:AAPL",
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "dark",
        "style": "1",
        "locale": "en",
        "allow_symbol_change": true,
        "calendar": false,
        "support_host": "https://www.tradingview.com"
        });
        widgetPlaceholder.appendChild(script);
    },
}
</script>

<style lang="scss" scoped>
    .trade{
        width: 100%;
        height: 100%;
        &-title{
            text-align: center;
            color: #fff;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 30px;
        }
    }
</style>