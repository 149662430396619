<template>
    <header class="header">
        <div class="header-left">
            <router-link to="/" class="header-logo">
                <img src="@/assets/images/logo.svg" alt="">
            </router-link>
        </div>
        <div class="header-right">
            <div class="header-settings pc" @click="logout">
                <img src="@/assets/images/logout.png" alt="">
            </div>
            <div class="menu mob">
                <input type="checkbox" id="burger-checkbox" class="burger-checkbox">
                <label class="burger" for="burger-checkbox"></label>
                <div class="menu-list">
                    <div class="aside-top">
                        <div class="aside-menu">
                            <router-link to="/" class="aside-menu-item">
                            <div class="aside-menu-icon">
                                <img src="@/assets/images/menu.png" alt="" />
                            </div>
                            <div class="aside-menu-title">{{ $t("aside.dashboard") }}</div>
                            </router-link>
                            <router-link to="/personal" class="aside-menu-item">
                            <div class="aside-menu-icon">
                                <img src="@/assets/images/user.png" alt="" />
                            </div>
                            <div class="aside-menu-title">{{ $t("aside.personal") }}</div>
                            </router-link>
                            <router-link to="/withdrawal" class="aside-menu-item">
                            <div class="aside-menu-icon">
                                <img src="@/assets/images/withdraw.png" alt="" />
                            </div>
                            <div class="aside-menu-title">{{ $t("aside.withdraw") }}</div>
                            </router-link>
                            <router-link to="/verification" class="aside-menu-item">
                            <div class="aside-menu-icon">
                                <img src="@/assets/images/verification.png" alt="" />
                            </div>
                            <div class="aside-menu-title">{{ $t("aside.verifaction") }}</div>
                            </router-link>
                            <router-link to="/accounts" class="aside-menu-item">
                            <div class="aside-menu-icon">
                                <img src="@/assets/images/accounts.png" alt="" />
                            </div>
                            <div class="aside-menu-title">{{ $t("aside.accounts") }}</div>
                            </router-link>
                            <router-link to="/savings" class="aside-menu-item">
                            <div class="aside-menu-icon">
                                <img src="@/assets/images/savings.png" alt="" />
                            </div>
                            <div class="aside-menu-title">{{ $t("aside.savings") }}</div>
                            </router-link>
                            <router-link to="/trade" class="aside-menu-item">
                            <div class="aside-menu-icon">
                                <img src="@/assets/images/trade.png" alt="" />
                            </div>
                            <div class="aside-menu-title">{{ $t("aside.trade") }}</div>
                            </router-link>
                            <router-link to="/settings" class="aside-menu-item">
                            <div class="aside-menu-icon">
                                <img src="@/assets/images/settings.png" alt="" />
                            </div>
                            <div class="aside-menu-title">{{ $t("aside.settings") }}</div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router';

const { t, locale } = useI18n()
const langActive = ref(false)
const route = useRoute()
const router = useRouter()

locale.value = route.query.lang || 'en';

const changeLangeActive = () => {
    if(langActive.value == false){
        langActive.value = true
    } else{
        langActive.value = false
    }
}

const logout = () => {
    router.push('/login')
}

const changeLang = (lang) => {
    locale.value = lang
}
</script>