<template>
  <HeaderBlock />
  <div class="box">
    <AsideBlock />
    <main class="main">
      <div class="py-4 grid grid-cols-1 md:grid-cols-3 gap-5">
        <article class="border-2 border-yellow p-4 rounded-xl bg-zinc-900">
          <div class="text-white flex items-center">
            <img src="@/assets/new-images/usa-icon.png" class="w-8 h-8 mt-1" />
            <div class="text-3xl ml-4">USD {{ $t('dashboard.check') }}</div>
          </div>
          <div class="text-white py-2">
            <p class="text-xl">{{ $t('dashboard.balance') }}</p>
            <p class="text-2xl">$ 0,00</p>
            <p class="text-emerald-400">$0,00</p>
          </div>
          <div class="text-white py-2">
            <p class="text-xl">{{ $t('dashboard.leverage') }}</p>
            <p>1:100</p>
          </div>
          <div class="text-white py-2">
            <p class="text-xl">{{ $t('dashboard.leverage') }}</p>
            <p>$0,00</p>
          </div>
          <div class="mt-2">
            <button class="h-14 w-full px-5 btn-yellow rounded-full">
              <span class="text-xl text-black">{{ $t('dashboard.trade_now') }}</span>
            </button>
          </div>
        </article>
        <article class="bg-zinc-900 p-4 rounded-2xl flex items-center">
          <img src="@/assets/new-images/continuous-improvement.png" class="w-24 h-24 ml-5" />
          <div class="text-white font-medium pl-4">
            <p class="text-2xl pb-2">{{ $t('dashboard.total_balance') }}</p>
            <p class="text-3xl">$0,00</p>
            <p class="font-normal text-lg">$0,00</p>
          </div>
        </article>
        <article class="bg-zinc-900 p-4 rounded-2xl flex items-center">
          <img src="@/assets/new-images/up-and-down.png" class="w-24 h-24 ml-5" />
          <div class="text-white font-medium pl-4">
            <p class="text-2xl pb-2">{{ $t('dashboard.pnl') }}</p>
            <div class="text-2xl flex items-center">
              <img src="@/assets/new-images/usa-icon.png" class="w-8 h-8 mr-2" />
              <span class="text-teal-400">$0,00</span>
            </div>
            <p class="font-normal text-lg pt-2">$0,00</p>
          </div>
        </article>
      </div>
      <div class="bg-zinc-900 p-4 rounded-2xl">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-5 border-slate-800 border-r">
            <div class="border-slate-800 border-r min-h-28">
              <div class="text-3xl text-white">{{ $t('dashboard.total_balance') }}</div>
              <div class="flex mt-2">
                <img src="@/assets/new-images/usa-icon.png" class="w-8 h-8 mt-1" />
                <div class="ml-2 text-white">
                  <p class="text-4xl">$0,00</p>
                  <p class="text-lg pl-2">$0,00</p>
                </div>
              </div>
            </div>
            <div class="min-h-28">
              <div class="text-3xl text-white">{{ $t('dashboard.total_deposit') }}</div>
              <div class="flex mt-2">
                <img src="@/assets/new-images/usa-icon.png" class="w-8 h-8 mt-1" />
                <div class="ml-2 text-white">
                  <p class="text-4xl">$0</p>
                  <p class="text-lg pl-2">$0,00</p>
                </div>
              </div>
            </div>
            <div class="border-slate-800 border-r min-h-28">
              <div class="text-xl text-white flex items-center">
                <img src="@/assets/new-images/up-arrow.png" class="w-6 h-6 mt-1" />
                <span class="px-2">{{ $t('dashboard.profitable_orders') }}</span>
              </div>
              <div class="flex mt-2">
                <div class="ml-2 text-white">
                  <p class="text-3xl pl-5">0 | 0</p>
                </div>
              </div>
            </div>
            <div class="min-h-28">
              <div class="text-xl text-white flex items-center">
                <img src="@/assets/new-images/down-arrow.png" class="w-6 h-6 mt-1 rotate-180" />
                <span class="px-2">{{ $t('dashboard.total_profit_and_loss') }}</span>
              </div>
              <div class="flex mt-2">
                <img src="@/assets/new-images/usa-icon.png" class="w-8 h-8 mt-1" />
                <div class="ml-2 text-white">
                  <p class="text-3xl text-teal-400">$0,00</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="text-2xl text-white">{{ $t('dashboard.success_rate') }}</div>
            <div class="relative flex items-center justify-center">
              <svg width="170" height="170" viewBox="0 0 40 40" class="donut">
                <circle class="donut-hole" cx="20" cy="20" r="16" fill="transparent"></circle>
                <circle
                  stroke="#EBEBEB"
                  cx="20"
                  cy="20"
                  r="16"
                  fill="transparent"
                  stroke-width="3.5"
                ></circle>
                <circle
                  class="origin-center"
                  cx="20"
                  cy="20"
                  r="16"
                  fill="transparent"
                  stroke-width="3.5"
                  :stroke-dasharray="percentString"
                  stroke-dashoffset="25"
                  stroke-linecap="round"
                  stroke="#FF6200"
                ></circle>
              </svg>
              <span class="text-2xl absolute text-white">{{ percent }}%</span>
            </div>
            <div class="grid justify-center mt-4">
              <div class="flex items-center">
                <div class="bg-orange-500 w-3 h-3 rounded-full"></div>
                <div class="ml-2">{{ $t('dashboard.pending') }}</div>
              </div>
              <div class="flex items-center">
                <div class="bg-white w-3 h-3 rounded-full"></div>
                <div class="ml-2">{{ $t('dashboard.not_orders') }}</div>
              </div>
              <div class="flex items-center">
                <div class="bg-emerald-400 w-3 h-3 rounded-full"></div>
                <div class="ml-2">{{ $t('dashboard.closed_with_profit') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-10">
        <article class="bg-zinc-900 p-4 rounded-2xl border-l-2 border-orange-500">
          <div class="flex items-center pl-4">
            <img src="@/assets/new-images/bitcoin-btc-logo.svg" class="w-10" />
            <div class="ml-4 flex-1">
              <div class="flex">
                <p class="text-xl">Bitcoin</p>
                <p class="ml-auto text-xl">BTC</p>
              </div>
              <p class="text-white text-2xl">USD 82544.91</p>
            </div>
          </div>
          <div class="pt-4 pb-2 flex items-center">
            <div class="py-3 border-b border-slate-300 flex-1 mx-6"></div>
            <div class="text-teal-400 text-2xl font-medium">+2.71%</div>
          </div>
        </article>
        <article class="bg-zinc-900 p-4 rounded-2xl border-l-2 border-red-800">
          <div class="flex items-center pl-4">
            <img src="@/assets/new-images/tron.svg" class="w-10" />
            <div class="ml-4 flex-1">
              <div class="flex">
                <p class="text-xl">Tron</p>
                <p class="ml-auto text-xl">TRX</p>
              </div>
              <p class="text-white text-2xl">USD 0.16570</p>
            </div>
          </div>
          <div class="pt-4 pb-2 flex items-center">
            <div class="py-3 border-b border-slate-300 flex-1 mx-6"></div>
            <div class="text-teal-400 text-2xl font-medium">+0.61%</div>
          </div>
        </article>
        <article class="bg-zinc-900 p-4 rounded-2xl border-l-2 border-indigo-500">
          <div class="flex items-center pl-4">
            <img src="@/assets/new-images/solana-sol-logo.svg" class="w-10" />
            <div class="ml-4 flex-1">
              <div class="flex">
                <p class="text-xl">Solana</p>
                <p class="ml-auto text-xl">SOL</p>
              </div>
              <p class="text-white text-2xl">USD 219</p>
            </div>
          </div>
          <div class="pt-4 pb-2 flex items-center">
            <div class="py-3 border-b border-slate-300 flex-1 mx-6"></div>
            <div class="text-teal-400 text-2xl font-medium">+4.21%</div>
          </div>
        </article>
        <article class="bg-zinc-900 p-4 rounded-2xl border-l-2 border-amber-400">
          <div class="flex items-center pl-4">
            <img src="@/assets/new-images/binance-logo.svg" class="w-10" />
            <div class="ml-4 flex-1">
              <div class="flex">
                <p class="text-xl">Binance</p>
                <p class="ml-auto text-xl">BNB</p>
              </div>
              <p class="text-white text-2xl">USD 620</p>
            </div>
          </div>
          <div class="pt-4 pb-2 flex items-center">
            <div class="py-3 border-b border-slate-300 flex-1 mx-6"></div>
            <div class="text-rose-600 text-2xl font-medium">-3.51%</div>
          </div>
        </article>
      </div>
      <div class="bg-zinc-900 p-4 rounded-2xl mt-8">
        <div class="flex gap-3">
          <button class="h-12 bg-neutral-950 px-4 rounded-2xl">{{ $t('dashboard.week') }}</button>
          <button class="h-12 px-4 rounded-2xl">{{ $t('dashboard.month') }}</button>
        </div>
        <div class="min-h-80"></div>
        <div class="py-2 border-b border-slate-300"></div>
      </div>
    </main>
  </div>
</template>

<script setup>
import HeaderBlock from "@/components/HeaderBlock.vue";
import AsideBlock from "@/components/AsideBlock.vue";

const percent = 69;
const percentString = `${percent} ${100 - percent}`;
</script>
