import { createRouter, createWebHistory } from "vue-router";
import TradeView from "../views/TradeView.vue";
import PersonalView from "@/views/PersonalView.vue";
import LoginView from "@/views/LoginView.vue";
import DashboardView from "@/views/DashboardView.vue";
import WithdrawalView from "@/views/WithdrawalView.vue";
import VerificationView from "@/views/VerificationView.vue";
import AccountsView from "@/views/AccountsView.vue";
import SavingsView from "@/views/SavingsView.vue";
import SettingsView from "@/views/SettingsView.vue";

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/trade",
    name: "trade",
    component: TradeView,
  },
  {
    path: "/personal",
    name: "personal",
    component: PersonalView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: WithdrawalView,
  },
  {
    path: "/verification",
    name: "verification",
    component: VerificationView,
  },
  {
    path: "/accounts",
    name: "accounts",
    component: AccountsView,
  },
  {
    path: "/savings",
    name: "savings",
    component: SavingsView,
  },
  ,
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
